@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

.icon-centering {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    margin: 0;
}
