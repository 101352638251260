.button-holder {
    display: flex;
    background-color: #24242d;
    border-radius: 8px;
    height: 40px;
}

.link-item {
    display: block;
    height: inherit;
    margin-bottom: 60px;
    text-decoration: none;
    font-family: Arial, sans-serif;
    color: grey;
}

.link-item.selected {
    color: white;
}

.project-selected {
    height: 3px;
    background-color: #17a3f8;
}

.project-selected.active {
    display: block;
}

.project-selected.inactive {
    display: none;
}
