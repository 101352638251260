table {
    border-collapse: collapse;
    border-spacing: 0;
    font-family: arial, sans-serif;
    table-layout: fixed;
    width: 100%;
    font-family: sans-serif;
    font-size: 14px;
}

thead {
    background: white;
    margin: 0;
    position: sticky;
    top: 0;
}

th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 2px 4px;
    text-align: left;
}

td {
    padding: 6px;
}

tbody {
    min-height: 50vh;
}

.container {
    /* border: 1px solid lightgray; */
    height: 50vh;
    overflow: auto;
}
