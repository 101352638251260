.link-item2 {
    display: block;
    height: inherit;
    margin-bottom: 60px;
    text-decoration: none;
    font-family: Arial, sans-serif;
    color: grey;
}

.link-item2.active2 {
    color: white;
}
